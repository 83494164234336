import OpenAPIClientAxios, { Document } from 'openapi-client-axios'
import PKG from '../package.json'
import { getToken } from './cookie'
import { isClientSide, isDev, isServerSide } from './env'
// import { API_URL } from '../constants/env'
import { Client, APIDocument } from '@gym-rose/data/apiClient'

const uuidStorageKey = 'x-uuid'
const genUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
const uuid = genUUID()

if (isClientSide) {
  if (!sessionStorage.getItem(uuidStorageKey))
    sessionStorage.setItem(uuidStorageKey, uuid)
}

let clientInstance: Client | null = null

export const initApiClient = () => {
  if (clientInstance) {
    return
  }

  const openApiClient = new OpenAPIClientAxios({
    definition: APIDocument as Document
  })

  clientInstance = openApiClient.initSync<Client>()

  clientInstance.defaults.baseURL = 'https://service.rosegym.com/' //API_URL
  // clientInstance.defaults.baseURL = API_URL
  clientInstance.defaults.timeout = 8000
  if (typeof window === 'undefined')
    clientInstance.defaults.headers.common['User-Agent'] =
      `NextJS/v${PKG.dependencies.next} ${PKG.name}/${PKG.version}`

  clientInstance.interceptors.request.use(async config => {
    const token = await getToken()
    if (config.headers) {
      if (token) {
        config.headers['Authorization'] = `bearer ${token}`
      }
      config.headers['x-session-uuid'] =
        globalThis?.sessionStorage?.getItem(uuidStorageKey) ?? uuid
    }

    //todo refresh token
    if (isDev && isServerSide) {
      console.log(`[Request]: ${config.url}`)
    }

    return config
  })

  clientInstance.interceptors.response.use(
    response => {
      if (isDev && isServerSide) {
        console.log(`[Response]: ${response.config.url}`)
      }

      return response
    },
    error => {
      if (isDev && isServerSide) {
        console.log(`[Response Error]: ${error.config.url}, ${error.message}`)
      }
      isDev && console.log('[Response Error]', error)
      const message =
        error?.response?.data?.message ||
        error?.response?.statusText ||
        error?.message ||
        '未知错误'
      const code = error?.response?.status || -1
      return Promise.reject({ code, message })
    }
  )
}

export const apiClient = new Proxy(
  {},
  {
    get: function (target, prop: keyof Client) {
      if (!clientInstance) {
        throw new Error('Client is not initialized')
      }
      return clientInstance[prop]
    }
  }
) as Client
