import Cookies from 'js-cookie'
import { isServerSide } from './env'

export const TokenKey = 'rose-token'

export async function getToken(): Promise<string | null> {
  if (isServerSide) {
    const { cookies } = await import('next/headers')
    return cookies().get(TokenKey)?.value || null
  }

  const token = Cookies.get(TokenKey)

  return token || null
}

export function setToken(token: string) {
  if (typeof token !== 'string') {
    return
  }
  return Cookies.set(TokenKey, token, {
    expires: 14
  })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
