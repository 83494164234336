// src/apiClient/apiDocument.ts
var APIDocument = {
  openapi: "3.0.0",
  info: {
    title: "Rose GYM API",
    version: "v1.0"
  },
  components: {
    schemas: {
      "UserController-getUserResponse": {
        type: "object",
        properties: {
          id: {
            type: "number"
          },
          name: {
            type: "string"
          },
          email: {
            type: "string"
          },
          intro: {
            type: "string"
          },
          avatar: {
            type: "string"
          },
          ownedGyms: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                name: {
                  type: "string",
                  minLength: 3,
                  maxLength: 15
                },
                desc: {
                  type: "string",
                  nullable: true,
                  maxLength: 100
                },
                openTime: {
                  type: "string"
                },
                closeTime: {
                  type: "string"
                },
                coordinates: {
                  type: "string"
                },
                avatar: {
                  type: "string",
                  nullable: true
                },
                address: {
                  type: "string",
                  minLength: 3,
                  maxLength: 30
                },
                ownerId: {
                  type: "string"
                },
                phoneNum: {
                  type: "string"
                }
              },
              required: [
                "id",
                "name",
                "openTime",
                "closeTime",
                "coordinates",
                "address",
                "ownerId",
                "phoneNum"
              ]
            }
          }
        },
        required: ["id", "ownedGyms"]
      },
      "UserController-getUserByIdResponse": {
        type: "object",
        properties: {
          id: {
            type: "number"
          },
          name: {
            type: "string"
          },
          email: {
            type: "string"
          },
          intro: {
            type: "string"
          },
          avatar: {
            type: "string"
          }
        },
        required: ["id"]
      },
      "UserController-getUsersResponse": {
        type: "object",
        properties: {
          users: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "number"
                },
                name: {
                  type: "string"
                },
                email: {
                  type: "string"
                },
                intro: {
                  type: "string"
                },
                avatar: {
                  type: "string"
                }
              },
              required: ["id"]
            }
          }
        },
        required: ["users"]
      },
      "UserController-updateUserBodyRequest": {
        type: "object",
        properties: {
          name: {
            type: "string",
            minLength: 1
          },
          email: {
            type: "string",
            format: "email"
          },
          intro: {
            type: "string"
          },
          avatar: {
            type: "string"
          },
          phoneNum: {
            type: "string"
          }
        }
      },
      "UserController-updateUserResponse": {
        type: "object",
        properties: {
          id: {
            type: "number"
          },
          name: {
            type: "string"
          },
          email: {
            type: "string"
          },
          intro: {
            type: "string"
          },
          avatar: {
            type: "string"
          }
        },
        required: ["id"]
      },
      "AuthController-registerBodyRequest": {
        type: "object",
        properties: {
          phoneNum: {
            type: "string",
            minLength: 11,
            maxLength: 11
          },
          password: {
            type: "string",
            minLength: 6,
            maxLength: 20
          }
        },
        required: ["phoneNum", "password"]
      },
      "AuthController-registerResponse": {
        type: "object",
        properties: {
          token: {
            type: "string"
          },
          id: {
            type: "string"
          },
          name: {
            type: "string"
          },
          avatar: {
            type: "string"
          },
          role: {
            type: "string"
          }
        },
        required: ["token"]
      },
      "AuthController-wechatOAthBodyRequest": {
        type: "object",
        properties: {
          code: {
            type: "string"
          }
        },
        required: ["code"]
      },
      "AuthController-wechatOAthResponse": {
        type: "object",
        properties: {
          token: {
            type: "string"
          },
          id: {
            type: "string"
          },
          name: {
            type: "string"
          },
          avatar: {
            type: "string"
          },
          role: {
            type: "string"
          }
        },
        required: ["token"]
      },
      "AuthController-loginWithPhoneNumBodyRequest": {
        type: "object",
        properties: {
          phoneNum: {
            type: "string",
            minLength: 11,
            maxLength: 11
          },
          password: {
            type: "string",
            minLength: 6,
            maxLength: 20
          }
        },
        required: ["phoneNum", "password"]
      },
      "AuthController-loginWithPhoneNumResponse": {
        type: "object",
        properties: {
          token: {
            type: "string"
          },
          id: {
            type: "string"
          },
          name: {
            type: "string"
          },
          avatar: {
            type: "string"
          },
          role: {
            type: "string"
          }
        },
        required: ["token"]
      },
      "fileUploadController-uploadImageBodyRequest": {
        type: "object",
        properties: {
          width: {
            type: "string"
          },
          height: {
            type: "string"
          },
          image: {
            nullable: true
          }
        }
      },
      "fileUploadController-uploadImageResponse": {
        type: "object",
        properties: {
          src: {
            type: "string"
          }
        },
        required: ["src"]
      },
      "GymController-getGymsResponse": {
        type: "object",
        properties: {
          gyms: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                name: {
                  type: "string",
                  minLength: 3,
                  maxLength: 15
                },
                desc: {
                  type: "string",
                  nullable: true,
                  maxLength: 100
                },
                openTime: {
                  type: "string"
                },
                closeTime: {
                  type: "string"
                },
                coordinates: {
                  type: "string"
                },
                avatar: {
                  type: "string",
                  nullable: true
                },
                address: {
                  type: "string",
                  minLength: 3,
                  maxLength: 30
                },
                ownerId: {
                  type: "string"
                },
                phoneNum: {
                  type: "string"
                }
              },
              required: [
                "id",
                "name",
                "openTime",
                "closeTime",
                "coordinates",
                "address",
                "ownerId",
                "phoneNum"
              ]
            }
          }
        },
        required: ["gyms"]
      },
      "GymController-createGymBodyRequest": {
        type: "object",
        properties: {
          name: {
            type: "string",
            minLength: 3,
            maxLength: 15
          },
          desc: {
            type: "string",
            nullable: true,
            maxLength: 100
          },
          openTime: {
            type: "string"
          },
          closeTime: {
            type: "string"
          },
          coordinates: {
            type: "string"
          },
          avatar: {
            type: "string",
            nullable: true
          },
          address: {
            type: "string",
            minLength: 3,
            maxLength: 30
          },
          ownerId: {
            type: "string"
          },
          phoneNum: {
            type: "string"
          }
        },
        required: ["name", "openTime", "closeTime", "address", "phoneNum"]
      },
      "GymController-createGymResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          name: {
            type: "string",
            minLength: 3,
            maxLength: 15
          },
          desc: {
            type: "string",
            nullable: true,
            maxLength: 100
          },
          openTime: {
            type: "string"
          },
          closeTime: {
            type: "string"
          },
          coordinates: {
            type: "string"
          },
          avatar: {
            type: "string",
            nullable: true
          },
          address: {
            type: "string",
            minLength: 3,
            maxLength: 30
          },
          ownerId: {
            type: "string"
          },
          phoneNum: {
            type: "string"
          }
        },
        required: [
          "id",
          "name",
          "openTime",
          "closeTime",
          "coordinates",
          "address",
          "ownerId",
          "phoneNum"
        ]
      },
      "GymController-updateGymBodyRequest": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          name: {
            type: "string",
            minLength: 3,
            maxLength: 15
          },
          desc: {
            type: "string",
            nullable: true,
            maxLength: 100
          },
          openTime: {
            type: "string"
          },
          closeTime: {
            type: "string"
          },
          coordinates: {
            type: "string"
          },
          avatar: {
            type: "string",
            nullable: true
          },
          address: {
            type: "string",
            minLength: 3,
            maxLength: 30
          },
          ownerId: {
            type: "string"
          },
          phoneNum: {
            type: "string"
          }
        }
      },
      "GymController-updateGymResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          name: {
            type: "string",
            minLength: 3,
            maxLength: 15
          },
          desc: {
            type: "string",
            nullable: true,
            maxLength: 100
          },
          openTime: {
            type: "string"
          },
          closeTime: {
            type: "string"
          },
          coordinates: {
            type: "string"
          },
          avatar: {
            type: "string",
            nullable: true
          },
          address: {
            type: "string",
            minLength: 3,
            maxLength: 30
          },
          ownerId: {
            type: "string"
          },
          phoneNum: {
            type: "string"
          }
        },
        required: [
          "id",
          "name",
          "openTime",
          "closeTime",
          "coordinates",
          "address",
          "ownerId",
          "phoneNum"
        ]
      },
      "GymController-getGymMembersResponse": {
        type: "object",
        properties: {
          members: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                name: {
                  type: "string"
                },
                avatar: {
                  type: "string"
                },
                phoneNum: {
                  type: "string"
                }
              },
              required: ["id", "name", "avatar", "phoneNum"]
            }
          }
        },
        required: ["members"]
      },
      "GymController-getGymCoachesResponse": {
        type: "object",
        properties: {
          coaches: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                name: {
                  type: "string"
                },
                avatar: {
                  type: "string"
                },
                phoneNum: {
                  type: "string"
                },
                intro: {
                  type: "string"
                },
                coachCourses: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: {
                        type: "string"
                      },
                      name: {
                        type: "string"
                      }
                    },
                    required: ["id", "name"]
                  }
                }
              },
              required: [
                "id",
                "name",
                "avatar",
                "phoneNum",
                "intro",
                "coachCourses"
              ]
            }
          }
        },
        required: ["coaches"]
      },
      "GymController-getGymCoachResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          name: {
            type: "string"
          },
          avatar: {
            type: "string"
          },
          phoneNum: {
            type: "string"
          },
          intro: {
            type: "string"
          },
          coachCourses: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                name: {
                  type: "string"
                }
              },
              required: ["id", "name"]
            }
          },
          isCoach: {
            type: "boolean"
          }
        },
        required: [
          "id",
          "name",
          "avatar",
          "phoneNum",
          "intro",
          "coachCourses",
          "isCoach"
        ]
      },
      "GymController-registerCoachBodyRequest": {
        type: "object",
        properties: {
          userId: {
            type: "string",
            minLength: 1
          }
        },
        required: ["userId"]
      },
      "GymController-registerCoachResponse": {
        type: "object",
        properties: {}
      },
      "GymController-updateCoachBodyRequest": {
        type: "object",
        properties: {
          intro: {
            type: "string",
            minLength: 1,
            maxLength: 150
          },
          coursesId: {
            type: "array",
            items: {
              type: "string"
            }
          }
        },
        required: ["intro", "coursesId"]
      },
      "GymController-updateCoachResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          name: {
            type: "string"
          },
          avatar: {
            type: "string"
          },
          phoneNum: {
            type: "string"
          },
          intro: {
            type: "string"
          },
          coachCourses: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                name: {
                  type: "string"
                }
              },
              required: ["id", "name"]
            }
          }
        },
        required: ["id", "name", "avatar", "phoneNum", "intro", "coachCourses"]
      },
      "BannerController-getBannersResponse": {
        type: "object",
        properties: {
          banners: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                createdAt: {
                  type: "string"
                },
                gymId: {
                  type: "string"
                },
                imgUrl: {
                  type: "string"
                },
                clickUrl: {
                  type: "string",
                  nullable: true
                }
              },
              required: ["id", "createdAt", "gymId", "imgUrl"]
            }
          }
        },
        required: ["banners"]
      },
      "BannerController-createBannerBodyRequest": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          gymId: {
            type: "string"
          },
          imgUrl: {
            type: "string"
          },
          clickUrl: {
            type: "string",
            nullable: true
          }
        },
        required: ["id", "gymId", "imgUrl"]
      },
      "BannerController-createBannerResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          createdAt: {
            type: "string"
          },
          gymId: {
            type: "string"
          },
          imgUrl: {
            type: "string"
          },
          clickUrl: {
            type: "string",
            nullable: true
          }
        },
        required: ["id", "createdAt", "gymId", "imgUrl"]
      },
      "BannerController-getBannerResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          createdAt: {
            type: "string"
          },
          gymId: {
            type: "string"
          },
          imgUrl: {
            type: "string"
          },
          clickUrl: {
            type: "string",
            nullable: true
          }
        },
        required: ["id", "createdAt", "gymId", "imgUrl"]
      },
      "BannerController-updateBannerBodyRequest": {
        type: "object",
        properties: {
          imgUrl: {
            type: "string"
          },
          clickUrl: {
            type: "string",
            nullable: true
          }
        },
        required: ["imgUrl"]
      },
      "BannerController-updateBannerResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          createdAt: {
            type: "string"
          },
          gymId: {
            type: "string"
          },
          imgUrl: {
            type: "string"
          },
          clickUrl: {
            type: "string",
            nullable: true
          }
        },
        required: ["id", "createdAt", "gymId", "imgUrl"]
      },
      "CourseController-getCoursesResponse": {
        type: "object",
        properties: {
          courses: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                name: {
                  type: "string",
                  minLength: 3,
                  maxLength: 30
                },
                type: {
                  type: "string"
                },
                desc: {
                  type: "string",
                  minLength: 3,
                  maxLength: 50
                },
                duration: {
                  type: "integer",
                  minimum: 30
                },
                maxMember: {
                  type: "integer",
                  minimum: 1
                },
                minMember: {
                  type: "integer",
                  minimum: 1
                },
                cancelPeriod: {
                  type: "integer",
                  minimum: 1
                },
                payTypes: {
                  type: "integer"
                },
                bgColor: {
                  type: "string"
                },
                gymId: {
                  type: "string"
                }
              },
              required: [
                "id",
                "name",
                "type",
                "desc",
                "duration",
                "maxMember",
                "minMember",
                "cancelPeriod",
                "payTypes",
                "bgColor",
                "gymId"
              ]
            }
          }
        },
        required: ["courses"]
      },
      "CourseController-getCourseResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          name: {
            type: "string",
            minLength: 3,
            maxLength: 30
          },
          type: {
            type: "string"
          },
          desc: {
            type: "string",
            minLength: 3,
            maxLength: 50
          },
          duration: {
            type: "integer",
            minimum: 30
          },
          maxMember: {
            type: "integer",
            minimum: 1
          },
          minMember: {
            type: "integer",
            minimum: 1
          },
          cancelPeriod: {
            type: "integer",
            minimum: 1
          },
          payTypes: {
            type: "integer"
          },
          bgColor: {
            type: "string"
          },
          gymId: {
            type: "string"
          }
        },
        required: [
          "id",
          "name",
          "type",
          "desc",
          "duration",
          "maxMember",
          "minMember",
          "cancelPeriod",
          "payTypes",
          "bgColor",
          "gymId"
        ]
      },
      "CourseController-createCourseBodyRequest": {
        type: "object",
        properties: {
          name: {
            type: "string",
            minLength: 3,
            maxLength: 30
          },
          type: {
            type: "string"
          },
          desc: {
            type: "string",
            minLength: 3,
            maxLength: 50
          },
          duration: {
            type: "integer",
            minimum: 30
          },
          maxMember: {
            type: "integer",
            minimum: 1
          },
          minMember: {
            type: "integer",
            minimum: 1
          },
          cancelPeriod: {
            type: "integer",
            minimum: 1
          },
          payTypes: {
            type: "integer"
          },
          bgColor: {
            type: "string"
          },
          gymId: {
            type: "string"
          }
        },
        required: [
          "name",
          "type",
          "desc",
          "duration",
          "maxMember",
          "minMember",
          "cancelPeriod",
          "payTypes",
          "bgColor",
          "gymId"
        ]
      },
      "CourseController-createCourseResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          name: {
            type: "string",
            minLength: 3,
            maxLength: 30
          },
          type: {
            type: "string"
          },
          desc: {
            type: "string",
            minLength: 3,
            maxLength: 50
          },
          duration: {
            type: "integer",
            minimum: 30
          },
          maxMember: {
            type: "integer",
            minimum: 1
          },
          minMember: {
            type: "integer",
            minimum: 1
          },
          cancelPeriod: {
            type: "integer",
            minimum: 1
          },
          payTypes: {
            type: "integer"
          },
          bgColor: {
            type: "string"
          },
          gymId: {
            type: "string"
          }
        },
        required: [
          "id",
          "name",
          "type",
          "desc",
          "duration",
          "maxMember",
          "minMember",
          "cancelPeriod",
          "payTypes",
          "bgColor",
          "gymId"
        ]
      },
      "CourseController-updateCourseBodyRequest": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          name: {
            type: "string",
            minLength: 3,
            maxLength: 30
          },
          type: {
            type: "string"
          },
          desc: {
            type: "string",
            minLength: 3,
            maxLength: 50
          },
          duration: {
            type: "integer",
            minimum: 30
          },
          maxMember: {
            type: "integer",
            minimum: 1
          },
          minMember: {
            type: "integer",
            minimum: 1
          },
          cancelPeriod: {
            type: "integer",
            minimum: 1
          },
          payTypes: {
            type: "integer"
          },
          bgColor: {
            type: "string"
          },
          gymId: {
            type: "string"
          }
        }
      },
      "CourseController-updateCourseResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          name: {
            type: "string",
            minLength: 3,
            maxLength: 30
          },
          type: {
            type: "string"
          },
          desc: {
            type: "string",
            minLength: 3,
            maxLength: 50
          },
          duration: {
            type: "integer",
            minimum: 30
          },
          maxMember: {
            type: "integer",
            minimum: 1
          },
          minMember: {
            type: "integer",
            minimum: 1
          },
          cancelPeriod: {
            type: "integer",
            minimum: 1
          },
          payTypes: {
            type: "integer"
          },
          bgColor: {
            type: "string"
          },
          gymId: {
            type: "string"
          }
        },
        required: [
          "id",
          "name",
          "type",
          "desc",
          "duration",
          "maxMember",
          "minMember",
          "cancelPeriod",
          "payTypes",
          "bgColor",
          "gymId"
        ]
      },
      "MembershipController-getMembershipsResponse": {
        type: "object",
        properties: {
          memberships: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                gymId: {
                  type: "string"
                },
                desc: {
                  type: "string",
                  minLength: 1
                },
                type: {
                  type: "string",
                  minLength: 1
                },
                name: {
                  type: "string",
                  minLength: 1
                },
                price: {
                  type: "number",
                  minimum: 0
                },
                showForSale: {
                  type: "boolean"
                },
                theme: {
                  type: "string",
                  enum: ["orange", "dark", "gold"]
                },
                courses: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: {
                        type: "string"
                      },
                      name: {
                        type: "string",
                        minLength: 3,
                        maxLength: 30
                      }
                    },
                    required: ["id", "name"]
                  }
                }
              },
              required: [
                "id",
                "gymId",
                "desc",
                "type",
                "name",
                "price",
                "showForSale",
                "theme",
                "courses"
              ]
            }
          }
        },
        required: ["memberships"]
      },
      "MembershipController-createMembershipBodyRequest": {
        type: "object",
        properties: {
          gymId: {
            type: "string"
          },
          desc: {
            type: "string",
            minLength: 1
          },
          type: {
            type: "string",
            minLength: 1
          },
          name: {
            type: "string",
            minLength: 1
          },
          price: {
            type: "number",
            minimum: 0
          },
          showForSale: {
            type: "boolean"
          },
          theme: {
            type: "string",
            nullable: true
          },
          courses: {
            type: "array",
            items: {
              type: "string"
            }
          }
        },
        required: [
          "gymId",
          "desc",
          "type",
          "name",
          "price",
          "showForSale",
          "courses"
        ]
      },
      "MembershipController-createMembershipResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          gymId: {
            type: "string"
          },
          desc: {
            type: "string",
            minLength: 1
          },
          type: {
            type: "string",
            minLength: 1
          },
          name: {
            type: "string",
            minLength: 1
          },
          price: {
            type: "number",
            minimum: 0
          },
          showForSale: {
            type: "boolean"
          },
          theme: {
            type: "string",
            enum: ["orange", "dark", "gold"]
          },
          courses: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                name: {
                  type: "string",
                  minLength: 3,
                  maxLength: 30
                }
              },
              required: ["id", "name"]
            }
          }
        },
        required: [
          "id",
          "gymId",
          "desc",
          "type",
          "name",
          "price",
          "showForSale",
          "theme",
          "courses"
        ]
      },
      "MembershipController-updateMembershipBodyRequest": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          gymId: {
            type: "string"
          },
          desc: {
            type: "string",
            minLength: 1
          },
          type: {
            type: "string",
            minLength: 1
          },
          name: {
            type: "string",
            minLength: 1
          },
          price: {
            type: "number",
            minimum: 0
          },
          showForSale: {
            type: "boolean"
          },
          theme: {
            type: "string",
            nullable: true
          },
          courses: {
            type: "array",
            items: {
              type: "string"
            }
          }
        }
      },
      "MembershipController-updateMembershipResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          gymId: {
            type: "string"
          },
          desc: {
            type: "string",
            minLength: 1
          },
          type: {
            type: "string",
            minLength: 1
          },
          name: {
            type: "string",
            minLength: 1
          },
          price: {
            type: "number",
            minimum: 0
          },
          showForSale: {
            type: "boolean"
          },
          theme: {
            type: "string",
            enum: ["orange", "dark", "gold"]
          },
          courses: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                name: {
                  type: "string",
                  minLength: 3,
                  maxLength: 30
                }
              },
              required: ["id", "name"]
            }
          }
        },
        required: [
          "id",
          "gymId",
          "desc",
          "type",
          "name",
          "price",
          "showForSale",
          "theme",
          "courses"
        ]
      },
      "CourseRecordController-createCourseRecordBodyRequest": {
        type: "object",
        properties: {
          startTime: {
            type: "string"
          },
          endTime: {
            type: "string",
            nullable: true
          },
          courseId: {
            type: "string",
            minLength: 1
          },
          coachId: {
            type: "string",
            minLength: 1
          },
          gymId: {
            type: "string",
            minLength: 1
          },
          status: {
            type: "string"
          }
        }
      },
      "CourseRecordController-createCourseRecordResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          startTime: {
            type: "string"
          },
          endTime: {
            type: "string",
            nullable: true
          },
          courseId: {
            type: "string",
            minLength: 1
          },
          coachId: {
            type: "string",
            minLength: 1
          },
          gymId: {
            type: "string",
            minLength: 1
          },
          status: {
            type: "string"
          },
          userRecords: {
            type: "array",
            items: {
              type: "object",
              properties: {
                user: {
                  type: "object",
                  properties: {
                    id: {
                      type: "string"
                    },
                    name: {
                      type: "string"
                    },
                    phoneNum: {
                      type: "string"
                    },
                    avatar: {
                      type: "string"
                    }
                  },
                  required: ["id", "name", "phoneNum"]
                },
                userMembership: {
                  type: "object",
                  properties: {
                    membership: {
                      type: "object",
                      properties: {
                        name: {
                          type: "string"
                        }
                      },
                      required: ["name"]
                    }
                  },
                  required: ["membership"]
                },
                status: {
                  type: "string",
                  enum: ["default", "checkedIn", "cancelByUser"]
                },
                createdAt: {
                  type: "string"
                }
              },
              required: ["user", "status", "createdAt"]
            }
          },
          _count: {
            type: "object",
            properties: {
              userRecords: {
                type: "number"
              }
            },
            required: ["userRecords"]
          },
          course: {
            type: "object",
            properties: {
              id: {
                type: "string"
              },
              name: {
                type: "string",
                minLength: 3,
                maxLength: 30
              },
              type: {
                type: "string"
              },
              desc: {
                type: "string",
                minLength: 3,
                maxLength: 50
              },
              duration: {
                type: "integer",
                minimum: 30
              },
              maxMember: {
                type: "integer",
                minimum: 1
              },
              minMember: {
                type: "integer",
                minimum: 1
              },
              cancelPeriod: {
                type: "integer",
                minimum: 1
              },
              payTypes: {
                type: "integer"
              },
              bgColor: {
                type: "string"
              },
              gymId: {
                type: "string"
              }
            },
            required: [
              "id",
              "name",
              "type",
              "desc",
              "duration",
              "maxMember",
              "minMember",
              "cancelPeriod",
              "payTypes",
              "bgColor",
              "gymId"
            ]
          },
          coach: {
            type: "object",
            properties: {
              id: {
                type: "number"
              },
              name: {
                type: "string"
              },
              email: {
                type: "string"
              },
              intro: {
                type: "string"
              },
              avatar: {
                type: "string"
              }
            },
            required: ["id"]
          }
        },
        required: [
          "id",
          "startTime",
          "courseId",
          "coachId",
          "gymId",
          "status",
          "userRecords",
          "_count",
          "course",
          "coach"
        ]
      },
      "CourseRecordController-getCourseRecordsResponse": {
        type: "object",
        properties: {
          records: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                startTime: {
                  type: "string"
                },
                endTime: {
                  type: "string",
                  nullable: true
                },
                courseId: {
                  type: "string",
                  minLength: 1
                },
                coachId: {
                  type: "string",
                  minLength: 1
                },
                gymId: {
                  type: "string",
                  minLength: 1
                },
                status: {
                  type: "string"
                },
                userRecords: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      user: {
                        type: "object",
                        properties: {
                          id: {
                            type: "string"
                          },
                          name: {
                            type: "string"
                          },
                          phoneNum: {
                            type: "string"
                          },
                          avatar: {
                            type: "string"
                          }
                        },
                        required: ["id", "name", "phoneNum"]
                      },
                      userMembership: {
                        type: "object",
                        properties: {
                          membership: {
                            type: "object",
                            properties: {
                              name: {
                                type: "string"
                              }
                            },
                            required: ["name"]
                          }
                        },
                        required: ["membership"]
                      },
                      status: {
                        type: "string",
                        enum: ["default", "checkedIn", "cancelByUser"]
                      },
                      createdAt: {
                        type: "string"
                      }
                    },
                    required: ["user", "status", "createdAt"]
                  }
                },
                _count: {
                  type: "object",
                  properties: {
                    userRecords: {
                      type: "number"
                    }
                  },
                  required: ["userRecords"]
                },
                course: {
                  type: "object",
                  properties: {
                    id: {
                      type: "string"
                    },
                    name: {
                      type: "string",
                      minLength: 3,
                      maxLength: 30
                    },
                    type: {
                      type: "string"
                    },
                    desc: {
                      type: "string",
                      minLength: 3,
                      maxLength: 50
                    },
                    duration: {
                      type: "integer",
                      minimum: 30
                    },
                    maxMember: {
                      type: "integer",
                      minimum: 1
                    },
                    minMember: {
                      type: "integer",
                      minimum: 1
                    },
                    cancelPeriod: {
                      type: "integer",
                      minimum: 1
                    },
                    payTypes: {
                      type: "integer"
                    },
                    bgColor: {
                      type: "string"
                    },
                    gymId: {
                      type: "string"
                    }
                  },
                  required: [
                    "id",
                    "name",
                    "type",
                    "desc",
                    "duration",
                    "maxMember",
                    "minMember",
                    "cancelPeriod",
                    "payTypes",
                    "bgColor",
                    "gymId"
                  ]
                },
                coach: {
                  type: "object",
                  properties: {
                    id: {
                      type: "number"
                    },
                    name: {
                      type: "string"
                    },
                    email: {
                      type: "string"
                    },
                    intro: {
                      type: "string"
                    },
                    avatar: {
                      type: "string"
                    }
                  },
                  required: ["id"]
                }
              },
              required: [
                "id",
                "startTime",
                "courseId",
                "coachId",
                "gymId",
                "status",
                "userRecords",
                "_count",
                "course",
                "coach"
              ]
            }
          }
        },
        required: ["records"]
      },
      "CourseRecordController-updateCourseRecordBodyRequest": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          startTime: {
            type: "string"
          },
          endTime: {
            type: "string",
            nullable: true
          },
          courseId: {
            type: "string",
            minLength: 1
          },
          coachId: {
            type: "string",
            minLength: 1
          },
          gymId: {
            type: "string",
            minLength: 1
          },
          status: {
            type: "string"
          }
        }
      },
      "CourseRecordController-updateCourseRecordResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          startTime: {
            type: "string"
          },
          endTime: {
            type: "string",
            nullable: true
          },
          courseId: {
            type: "string",
            minLength: 1
          },
          coachId: {
            type: "string",
            minLength: 1
          },
          gymId: {
            type: "string",
            minLength: 1
          },
          status: {
            type: "string"
          },
          userRecords: {
            type: "array",
            items: {
              type: "object",
              properties: {
                user: {
                  type: "object",
                  properties: {
                    id: {
                      type: "string"
                    },
                    name: {
                      type: "string"
                    },
                    phoneNum: {
                      type: "string"
                    },
                    avatar: {
                      type: "string"
                    }
                  },
                  required: ["id", "name", "phoneNum"]
                },
                userMembership: {
                  type: "object",
                  properties: {
                    membership: {
                      type: "object",
                      properties: {
                        name: {
                          type: "string"
                        }
                      },
                      required: ["name"]
                    }
                  },
                  required: ["membership"]
                },
                status: {
                  type: "string",
                  enum: ["default", "checkedIn", "cancelByUser"]
                },
                createdAt: {
                  type: "string"
                }
              },
              required: ["user", "status", "createdAt"]
            }
          },
          _count: {
            type: "object",
            properties: {
              userRecords: {
                type: "number"
              }
            },
            required: ["userRecords"]
          },
          course: {
            type: "object",
            properties: {
              id: {
                type: "string"
              },
              name: {
                type: "string",
                minLength: 3,
                maxLength: 30
              },
              type: {
                type: "string"
              },
              desc: {
                type: "string",
                minLength: 3,
                maxLength: 50
              },
              duration: {
                type: "integer",
                minimum: 30
              },
              maxMember: {
                type: "integer",
                minimum: 1
              },
              minMember: {
                type: "integer",
                minimum: 1
              },
              cancelPeriod: {
                type: "integer",
                minimum: 1
              },
              payTypes: {
                type: "integer"
              },
              bgColor: {
                type: "string"
              },
              gymId: {
                type: "string"
              }
            },
            required: [
              "id",
              "name",
              "type",
              "desc",
              "duration",
              "maxMember",
              "minMember",
              "cancelPeriod",
              "payTypes",
              "bgColor",
              "gymId"
            ]
          },
          coach: {
            type: "object",
            properties: {
              id: {
                type: "number"
              },
              name: {
                type: "string"
              },
              email: {
                type: "string"
              },
              intro: {
                type: "string"
              },
              avatar: {
                type: "string"
              }
            },
            required: ["id"]
          }
        },
        required: [
          "id",
          "startTime",
          "courseId",
          "coachId",
          "gymId",
          "status",
          "userRecords",
          "_count",
          "course",
          "coach"
        ]
      },
      "UserMembershipController-createUserMembershipBodyRequest": {
        type: "object",
        properties: {
          userId: {
            type: "string",
            minLength: 1
          },
          gymId: {
            type: "string",
            minLength: 1
          },
          membershipId: {
            type: "string",
            minLength: 1
          },
          totalTimes: {
            type: "integer",
            nullable: true
          },
          startTime: {
            type: "string",
            minLength: 1
          },
          endTime: {
            type: "string",
            nullable: true
          },
          daysDuration: {
            type: "integer",
            nullable: true,
            minimum: 1
          }
        },
        required: ["userId", "gymId", "membershipId", "startTime"]
      },
      "UserMembershipController-createUserMembershipResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          userId: {
            type: "string",
            minLength: 1
          },
          gymId: {
            type: "string",
            minLength: 1
          },
          membershipId: {
            type: "string",
            minLength: 1
          },
          totalTimes: {
            type: "integer",
            nullable: true
          },
          startTime: {
            type: "string"
          },
          endTime: {
            type: "string",
            nullable: true
          },
          daysDuration: {
            type: "integer",
            nullable: true,
            minimum: 1
          },
          membership: {
            type: "object",
            properties: {
              id: {
                type: "string"
              },
              gymId: {
                type: "string"
              },
              desc: {
                type: "string",
                minLength: 1
              },
              type: {
                type: "string",
                minLength: 1
              },
              name: {
                type: "string",
                minLength: 1
              },
              price: {
                type: "number",
                minimum: 0
              },
              showForSale: {
                type: "boolean"
              },
              theme: {
                type: "string",
                nullable: true
              }
            },
            required: [
              "id",
              "gymId",
              "desc",
              "type",
              "name",
              "price",
              "showForSale"
            ]
          },
          _count: {
            type: "object",
            properties: {
              records: {
                type: "number"
              }
            },
            required: ["records"]
          }
        },
        required: [
          "id",
          "userId",
          "gymId",
          "membershipId",
          "startTime",
          "membership",
          "_count"
        ]
      },
      "UserMembershipController-getUserMembershipsResponse": {
        type: "object",
        properties: {
          memberships: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                userId: {
                  type: "string",
                  minLength: 1
                },
                gymId: {
                  type: "string",
                  minLength: 1
                },
                membershipId: {
                  type: "string",
                  minLength: 1
                },
                totalTimes: {
                  type: "integer",
                  nullable: true
                },
                startTime: {
                  type: "string"
                },
                endTime: {
                  type: "string",
                  nullable: true
                },
                daysDuration: {
                  type: "integer",
                  nullable: true,
                  minimum: 1
                },
                membership: {
                  type: "object",
                  properties: {
                    id: {
                      type: "string"
                    },
                    gymId: {
                      type: "string"
                    },
                    desc: {
                      type: "string",
                      minLength: 1
                    },
                    type: {
                      type: "string",
                      minLength: 1
                    },
                    name: {
                      type: "string",
                      minLength: 1
                    },
                    price: {
                      type: "number",
                      minimum: 0
                    },
                    showForSale: {
                      type: "boolean"
                    },
                    theme: {
                      type: "string",
                      nullable: true
                    }
                  },
                  required: [
                    "id",
                    "gymId",
                    "desc",
                    "type",
                    "name",
                    "price",
                    "showForSale"
                  ]
                },
                _count: {
                  type: "object",
                  properties: {
                    records: {
                      type: "number"
                    }
                  },
                  required: ["records"]
                }
              },
              required: [
                "id",
                "userId",
                "gymId",
                "membershipId",
                "startTime",
                "membership",
                "_count"
              ]
            }
          }
        },
        required: ["memberships"]
      },
      "UserMembershipController-updateUserMembershipBodyRequest": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          userId: {
            type: "string",
            minLength: 1
          },
          gymId: {
            type: "string",
            minLength: 1
          },
          membershipId: {
            type: "string",
            minLength: 1
          },
          totalTimes: {
            type: "integer",
            nullable: true
          },
          startTime: {
            type: "string",
            minLength: 1
          },
          endTime: {
            type: "string",
            nullable: true
          },
          daysDuration: {
            type: "integer",
            nullable: true,
            minimum: 1
          }
        },
        required: ["id", "userId", "gymId", "membershipId", "startTime"]
      },
      "UserMembershipController-updateUserMembershipResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          userId: {
            type: "string",
            minLength: 1
          },
          gymId: {
            type: "string",
            minLength: 1
          },
          membershipId: {
            type: "string",
            minLength: 1
          },
          totalTimes: {
            type: "integer",
            nullable: true
          },
          startTime: {
            type: "string"
          },
          endTime: {
            type: "string",
            nullable: true
          },
          daysDuration: {
            type: "integer",
            nullable: true,
            minimum: 1
          }
        },
        required: ["id", "userId", "gymId", "membershipId", "startTime"]
      },
      "UserMembershipController-batchUpdateUserMembershipRecordsBodyRequest": {
        type: "object",
        properties: {
          userId: {
            type: "string",
            minLength: 1
          },
          userRecordIds: {
            type: "array",
            items: {
              type: "string"
            }
          },
          membershipId: {
            type: "string",
            minLength: 1
          }
        },
        required: ["userId", "userRecordIds", "membershipId"]
      },
      "UserMembershipController-batchUpdateUserMembershipRecordsResponse": {
        type: "object",
        properties: {
          memberships: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                userId: {
                  type: "string",
                  minLength: 1
                },
                gymId: {
                  type: "string",
                  minLength: 1
                },
                membershipId: {
                  type: "string",
                  minLength: 1
                },
                totalTimes: {
                  type: "integer",
                  nullable: true
                },
                startTime: {
                  type: "string"
                },
                endTime: {
                  type: "string",
                  nullable: true
                },
                daysDuration: {
                  type: "integer",
                  nullable: true,
                  minimum: 1
                }
              },
              required: ["id", "userId", "gymId", "membershipId", "startTime"]
            }
          }
        },
        required: ["memberships"]
      },
      "GymController-getGymResponse": {
        type: "object",
        properties: {
          name: {
            type: "string",
            minLength: 3,
            maxLength: 15
          },
          desc: {
            type: "string",
            nullable: true,
            maxLength: 100
          },
          openTime: {
            type: "string"
          },
          closeTime: {
            type: "string"
          },
          coordinates: {
            type: "string",
            nullable: true
          },
          avatar: {
            type: "string",
            nullable: true
          },
          address: {
            type: "string",
            minLength: 3,
            maxLength: 30
          },
          phoneNum: {
            type: "string",
            nullable: true
          },
          memberships: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                desc: {
                  type: "string",
                  minLength: 1
                },
                type: {
                  type: "string",
                  minLength: 1
                },
                name: {
                  type: "string",
                  minLength: 1
                },
                price: {
                  type: "number",
                  minimum: 0
                },
                theme: {
                  type: "string",
                  nullable: true
                },
                courses: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: {
                        type: "string"
                      },
                      name: {
                        type: "string",
                        minLength: 3,
                        maxLength: 30
                      },
                      type: {
                        type: "string"
                      },
                      desc: {
                        type: "string",
                        minLength: 3,
                        maxLength: 50
                      },
                      duration: {
                        type: "integer",
                        minimum: 30
                      },
                      maxMember: {
                        type: "integer",
                        minimum: 1
                      },
                      minMember: {
                        type: "integer",
                        minimum: 1
                      },
                      cancelPeriod: {
                        type: "integer",
                        minimum: 1
                      },
                      payTypes: {
                        type: "integer"
                      },
                      bgColor: {
                        type: "string"
                      }
                    },
                    required: [
                      "id",
                      "name",
                      "type",
                      "desc",
                      "duration",
                      "maxMember",
                      "minMember",
                      "cancelPeriod",
                      "payTypes",
                      "bgColor"
                    ]
                  }
                }
              },
              required: ["id", "desc", "type", "name", "price", "courses"]
            }
          },
          banners: {
            type: "array",
            items: {
              type: "object",
              properties: {
                imgUrl: {
                  type: "string"
                },
                clickUrl: {
                  type: "string",
                  nullable: true
                }
              },
              required: ["imgUrl"]
            }
          },
          coaches: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                name: {
                  type: "string"
                },
                avatar: {
                  type: "string"
                },
                phoneNum: {
                  type: "string"
                },
                intro: {
                  type: "string"
                },
                coachCourses: {
                  type: "array",
                  items: {
                    type: "object",
                    properties: {
                      id: {
                        type: "string"
                      },
                      name: {
                        type: "string"
                      }
                    },
                    required: ["id", "name"]
                  }
                }
              },
              required: [
                "id",
                "name",
                "avatar",
                "phoneNum",
                "intro",
                "coachCourses"
              ]
            }
          },
          courses: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                name: {
                  type: "string",
                  minLength: 3,
                  maxLength: 30
                },
                type: {
                  type: "string"
                },
                desc: {
                  type: "string",
                  minLength: 3,
                  maxLength: 50
                },
                duration: {
                  type: "integer",
                  minimum: 30
                },
                maxMember: {
                  type: "integer",
                  minimum: 1
                },
                minMember: {
                  type: "integer",
                  minimum: 1
                },
                cancelPeriod: {
                  type: "integer",
                  minimum: 1
                },
                payTypes: {
                  type: "integer"
                },
                bgColor: {
                  type: "string"
                }
              },
              required: [
                "id",
                "name",
                "type",
                "desc",
                "duration",
                "maxMember",
                "minMember",
                "cancelPeriod",
                "payTypes",
                "bgColor"
              ]
            }
          }
        },
        required: [
          "name",
          "openTime",
          "closeTime",
          "address",
          "memberships",
          "banners",
          "coaches",
          "courses"
        ]
      },
      "CourseRecordPublicController-getCourseRecordsResponse": {
        type: "object",
        properties: {
          records: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                startTime: {
                  type: "string"
                },
                endTime: {
                  type: "string",
                  nullable: true
                },
                courseId: {
                  type: "string",
                  minLength: 1
                },
                coachId: {
                  type: "string",
                  minLength: 1
                },
                status: {
                  type: "string"
                },
                _count: {
                  type: "object",
                  properties: {
                    userRecords: {
                      type: "number"
                    }
                  },
                  required: ["userRecords"]
                }
              },
              required: [
                "id",
                "startTime",
                "courseId",
                "coachId",
                "status",
                "_count"
              ]
            }
          }
        },
        required: ["records"]
      },
      "CoachController-getCoachRecordsResponse": {
        type: "object",
        properties: {
          records: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                startTime: {
                  type: "string"
                },
                endTime: {
                  type: "string",
                  nullable: true
                },
                courseId: {
                  type: "string",
                  minLength: 1
                },
                coachId: {
                  type: "string",
                  minLength: 1
                },
                status: {
                  type: "string"
                },
                _count: {
                  type: "object",
                  properties: {
                    userRecords: {
                      type: "number"
                    }
                  },
                  required: ["userRecords"]
                }
              },
              required: [
                "id",
                "startTime",
                "courseId",
                "coachId",
                "status",
                "_count"
              ]
            }
          }
        },
        required: ["records"]
      },
      "UserRecordController-createUserGroupRecordBodyRequest": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          userId: {
            type: "string",
            minLength: 1
          },
          gymId: {
            type: "string",
            minLength: 1
          },
          courseRecordId: {
            type: "string",
            minLength: 1
          },
          membershipId: {
            type: "string",
            nullable: true
          },
          payType: {
            type: "integer"
          },
          status: {
            type: "string",
            enum: ["default", "checkedIn", "cancelByUser"],
            default: "default"
          }
        }
      },
      "UserRecordController-createUserGroupRecordResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          userId: {
            type: "string",
            minLength: 1
          },
          gymId: {
            type: "string",
            minLength: 1
          },
          courseRecordId: {
            type: "string",
            minLength: 1
          },
          membershipId: {
            type: "string",
            nullable: true
          },
          payType: {
            type: "integer"
          },
          status: {
            type: "string",
            enum: ["default", "checkedIn", "cancelByUser"],
            default: "default"
          }
        },
        required: ["userId", "gymId", "courseRecordId", "payType", "status"]
      },
      "UserRecordController-createUserPrivateRecordBodyRequest": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          userId: {
            type: "string",
            minLength: 1
          },
          gymId: {
            type: "string",
            minLength: 1
          },
          membershipId: {
            type: "string",
            nullable: true
          },
          payType: {
            type: "integer"
          },
          status: {
            type: "string",
            enum: ["default", "checkedIn", "cancelByUser"],
            default: "default"
          },
          courseRecord: {
            type: "object",
            properties: {
              id: {
                type: "string"
              },
              startTime: {
                type: "string"
              },
              courseId: {
                type: "string",
                minLength: 1
              },
              coachId: {
                type: "string",
                minLength: 1
              },
              gymId: {
                type: "string",
                minLength: 1
              },
              status: {
                type: "string",
                enum: ["default", "cancelByAdmin", "cancelByUser"],
                default: "default"
              }
            },
            required: ["startTime", "courseId", "coachId", "gymId", "status"]
          }
        }
      },
      "UserRecordController-createUserPrivateRecordResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          userId: {
            type: "string",
            minLength: 1
          },
          gymId: {
            type: "string",
            minLength: 1
          },
          membershipId: {
            type: "string",
            nullable: true
          },
          payType: {
            type: "integer"
          },
          status: {
            type: "string",
            enum: ["default", "checkedIn", "cancelByUser"],
            default: "default"
          },
          courseRecord: {
            type: "object",
            properties: {
              id: {
                type: "string"
              },
              startTime: {
                type: "string"
              },
              courseId: {
                type: "string",
                minLength: 1
              },
              coachId: {
                type: "string",
                minLength: 1
              },
              gymId: {
                type: "string",
                minLength: 1
              },
              status: {
                type: "string",
                enum: ["default", "cancelByAdmin", "cancelByUser"],
                default: "default"
              }
            },
            required: ["startTime", "courseId", "coachId", "gymId", "status"]
          }
        },
        required: ["userId", "gymId", "payType", "status", "courseRecord"]
      },
      "UserRecordController-getUserRecordResponse": {
        type: "object",
        properties: {
          userRecords: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: {
                  type: "string"
                },
                status: {
                  type: "string",
                  enum: ["default", "checkedIn", "cancelByUser"]
                },
                payType: {
                  type: "integer"
                },
                membershipId: {
                  type: "string",
                  nullable: true
                },
                courseRecord: {
                  type: "object",
                  properties: {
                    id: {
                      type: "string"
                    },
                    startTime: {
                      type: "string"
                    },
                    endTime: {
                      type: "string",
                      nullable: true
                    },
                    courseId: {
                      type: "string",
                      minLength: 1
                    },
                    coachId: {
                      type: "string",
                      minLength: 1
                    },
                    status: {
                      type: "string"
                    },
                    _count: {
                      type: "object",
                      properties: {
                        userRecords: {
                          type: "number"
                        }
                      },
                      required: ["userRecords"]
                    }
                  },
                  required: [
                    "id",
                    "startTime",
                    "courseId",
                    "coachId",
                    "status",
                    "_count"
                  ]
                }
              },
              required: ["id", "status", "payType", "courseRecord"]
            }
          }
        },
        required: ["userRecords"]
      },
      "UserRecordController-updateUserRecordBodyRequest": {
        type: "object",
        properties: {
          status: {
            type: "string",
            enum: ["default", "checkedIn", "cancelByUser"]
          }
        },
        required: ["status"]
      },
      "UserRecordController-updateUserRecordResponse": {
        type: "object",
        properties: {
          id: {
            type: "string"
          },
          userId: {
            type: "string",
            minLength: 1
          },
          gymId: {
            type: "string",
            minLength: 1
          },
          courseRecordId: {
            type: "string",
            minLength: 1
          },
          membershipId: {
            type: "string",
            nullable: true
          },
          payType: {
            type: "integer"
          },
          status: {
            type: "string",
            enum: ["default", "checkedIn", "cancelByUser"],
            default: "default"
          }
        },
        required: ["userId", "gymId", "courseRecordId", "payType", "status"]
      }
    },
    parameters: {}
  },
  paths: {
    "/api/user": {
      get: {
        summary: "get current user",
        tags: ["User"],
        operationId: "getUser",
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/UserController-getUserResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/users/{id}": {
      get: {
        summary: "get user by id",
        tags: ["User"],
        operationId: "getUserById",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "id",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "query"
          }
        ],
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/UserController-getUserByIdResponse"
                }
              }
            }
          }
        }
      },
      put: {
        tags: ["User"],
        description: "update user by id",
        operationId: "updateUser",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "id",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/UserController-updateUserBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/UserController-updateUserResponse"
                }
              }
            }
          },
          "400": {
            description: "bad request"
          },
          "401": {
            description: "unauthorized, missing/wrong jwt token"
          }
        }
      },
      delete: {
        summary: "Delete user by id",
        operationId: "deleteUser",
        tags: ["User"],
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "id",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "success"
          },
          "400": {
            description: "bad request"
          },
          "401": {
            description: "unauthorized, missing/wrong jwt token"
          }
        }
      }
    },
    "/api/users": {
      get: {
        summary: "Find all users",
        tags: ["User"],
        operationId: "getUsers",
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/UserController-getUsersResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/testusers": {
      delete: {
        summary: "Delete users generated by integration and load tests",
        operationId: "deleteTestUsers",
        tags: ["User"],
        responses: {
          "200": {
            description: "success"
          },
          "400": {
            description: "bad request"
          },
          "401": {
            description: "unauthorized, missing/wrong jwt token"
          }
        }
      }
    },
    "/api/": {
      get: {
        summary: "Welcome page",
        operationId: "helloWorld",
        description: "A simple welcome message to verify the service is up and running.",
        tags: ["general"],
        responses: {
          "200": {
            description: "\u6210\u529F\u54CD\u5E94"
          }
        }
      }
    },
    "/api/register": {
      post: {
        summary: "Passport register sum",
        operationId: "register",
        tags: ["Auth"],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/AuthController-registerBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/AuthController-registerResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/auth": {
      post: {
        summary: "wechatOAth",
        operationId: "wechatOAth",
        tags: ["Auth"],
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: false,
            name: "gymId",
            in: "query"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/AuthController-wechatOAthBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/AuthController-wechatOAthResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/login": {
      post: {
        summary: "phone num password login",
        operationId: "loginWithPhoneNum",
        tags: ["Auth"],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/AuthController-loginWithPhoneNumBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/AuthController-loginWithPhoneNumResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/image": {
      post: {
        operationId: "uploadImage",
        summary: "upload image",
        tags: ["general"],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/fileUploadController-uploadImageBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/fileUploadController-uploadImageResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/gyms": {
      get: {
        summary: "Find all gyms",
        tags: ["Gym"],
        operationId: "getGyms",
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GymController-getGymsResponse"
                }
              }
            }
          }
        }
      },
      post: {
        summary: "Create a new gym",
        tags: ["Gym"],
        operationId: "createGym",
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/GymController-createGymBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GymController-createGymResponse"
                }
              }
            }
          }
        }
      },
      put: {
        summary: "Update gym by id",
        tags: ["Gym"],
        operationId: "updateGym",
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/GymController-updateGymBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GymController-updateGymResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/gyms/{id}": {
      delete: {
        summary: "Delete gym by id",
        tags: ["Gym"],
        operationId: "deleteGym",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "id",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "\u6210\u529F\u54CD\u5E94"
          }
        }
      },
      get: {
        summary: "Get gym by id",
        tags: ["GymPublic"],
        operationId: "getGym",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "id",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GymController-getGymResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/gyms/{id}/members": {
      get: {
        summary: "Get all members of a specific gym",
        tags: ["Gym"],
        operationId: "getGymMembers",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "id",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GymController-getGymMembersResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/gyms/{id}/coaches": {
      get: {
        summary: "Get all coaches of a specific gym",
        tags: ["Gym"],
        operationId: "getGymCoaches",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "id",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GymController-getGymCoachesResponse"
                }
              }
            }
          }
        }
      },
      post: {
        summary: "Add a coach to a gym",
        tags: ["Gym"],
        operationId: "registerCoach",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "id",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/GymController-registerCoachBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GymController-registerCoachResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/gyms/{id}/coaches/{phoneNum}": {
      get: {
        summary: "Get a specific coach of a gym",
        tags: ["Gym"],
        operationId: "getGymCoach",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "id",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "phoneNum",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GymController-getGymCoachResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/gyms/{id}/coaches/{cid}": {
      put: {
        summary: "Update a specific coach of a gym",
        tags: ["Gym"],
        operationId: "updateCoach",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "id",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "cid",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/GymController-updateCoachBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/GymController-updateCoachResponse"
                }
              }
            }
          }
        }
      },
      delete: {
        summary: "Delete a specific coach of a gym",
        tags: ["Gym"],
        operationId: "deleteCoach",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "id",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "cid",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "\u6210\u529F\u54CD\u5E94"
          }
        }
      }
    },
    "/api/gyms/{gymId}/banners": {
      get: {
        summary: "Find all banners",
        tags: ["Banner"],
        operationId: "getBanners",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/BannerController-getBannersResponse"
                }
              }
            }
          }
        }
      },
      post: {
        summary: "Create a new banner",
        tags: ["Banner"],
        operationId: "createBanner",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/BannerController-createBannerBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/BannerController-createBannerResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/gyms/{gymId}/banners/{bannerId}": {
      get: {
        summary: "Get banner by id",
        tags: ["Banner"],
        operationId: "getBanner",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "bannerId",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/BannerController-getBannerResponse"
                }
              }
            }
          }
        }
      },
      put: {
        summary: "Update banner by id",
        tags: ["Banner"],
        operationId: "updateBanner",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "bannerId",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/BannerController-updateBannerBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/BannerController-updateBannerResponse"
                }
              }
            }
          }
        }
      },
      delete: {
        summary: "Delete banner by id",
        tags: ["Banner"],
        operationId: "deleteBanner",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "bannerId",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "\u6210\u529F\u54CD\u5E94"
          }
        }
      }
    },
    "/api/gyms/{gymId}/courses": {
      get: {
        summary: "Find all courses in a gym",
        tags: ["Course"],
        operationId: "getCourses",
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/CourseController-getCoursesResponse"
                }
              }
            }
          }
        }
      },
      post: {
        summary: "Create a new course in a gym",
        tags: ["Course"],
        operationId: "createCourse",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/CourseController-createCourseBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/CourseController-createCourseResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/gyms/{gymId}/courses/{courseId}": {
      get: {
        summary: "Get course by id",
        tags: ["Course"],
        operationId: "getCourse",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "courseId",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/CourseController-getCourseResponse"
                }
              }
            }
          }
        }
      },
      put: {
        summary: "Update course by id",
        tags: ["Course"],
        operationId: "updateCourse",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "courseId",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/CourseController-updateCourseBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/CourseController-updateCourseResponse"
                }
              }
            }
          }
        }
      },
      delete: {
        summary: "Delete course by id",
        tags: ["Course"],
        operationId: "deleteCourse",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "courseId",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "\u6210\u529F\u54CD\u5E94"
          }
        }
      }
    },
    "/api/gyms/{gymId}/memberships": {
      get: {
        summary: "Find all memberships in a gym",
        tags: ["Membership"],
        operationId: "getMemberships",
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/MembershipController-getMembershipsResponse"
                }
              }
            }
          }
        }
      },
      post: {
        summary: "Create a new membership in a gym",
        tags: ["Membership"],
        operationId: "createMembership",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/MembershipController-createMembershipBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/MembershipController-createMembershipResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/gyms/{gymId}/memberships/{membershipId}": {
      put: {
        summary: "Update membership by id",
        tags: ["Membership"],
        operationId: "updateMembership",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "membershipId",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/MembershipController-updateMembershipBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/MembershipController-updateMembershipResponse"
                }
              }
            }
          }
        }
      },
      delete: {
        summary: "Delete membership by id",
        tags: ["Membership"],
        operationId: "deleteMembership",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "membershipId",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "\u6210\u529F\u54CD\u5E94"
          }
        }
      }
    },
    "/api/gyms/{gymId}/records": {
      post: {
        summary: "Create a new course record in a gym",
        tags: ["CourseRecord"],
        operationId: "createCourseRecord",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/CourseRecordController-createCourseRecordBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/CourseRecordController-createCourseRecordResponse"
                }
              }
            }
          }
        }
      },
      get: {
        summary: "Get course records by start date and period",
        tags: ["CourseRecord"],
        operationId: "getCourseRecords",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          },
          {
            schema: {
              type: "string"
            },
            required: false,
            name: "date",
            in: "query"
          },
          {
            schema: {
              type: "string"
            },
            required: false,
            name: "period",
            in: "query"
          },
          {
            schema: {
              type: "string"
            },
            required: false,
            name: "courseId",
            in: "query"
          },
          {
            schema: {
              type: "string"
            },
            required: false,
            name: "coachId",
            in: "query"
          },
          {
            schema: {
              type: "string"
            },
            required: false,
            name: "memberPhoneNum",
            in: "query"
          },
          {
            schema: {
              anyOf: [
                {
                  type: "string",
                  enum: ["default"]
                },
                {
                  type: "string",
                  enum: ["cancelByAdmin"]
                },
                {
                  type: "string",
                  enum: ["cancelByUser"]
                }
              ]
            },
            required: false,
            name: "courseStatus",
            in: "query"
          }
        ],
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/CourseRecordController-getCourseRecordsResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/gyms/{gymId}/records/{recordId}": {
      put: {
        summary: "Update course record by id",
        tags: ["CourseRecord"],
        operationId: "updateCourseRecord",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "recordId",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/CourseRecordController-updateCourseRecordBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/CourseRecordController-updateCourseRecordResponse"
                }
              }
            }
          }
        }
      },
      delete: {
        summary: "Delete course record by id",
        tags: ["CourseRecord"],
        operationId: "deleteCourseRecord",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "recordId",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "\u6210\u529F\u54CD\u5E94"
          }
        }
      }
    },
    "/api/gyms/{gymId}/user-memberships": {
      post: {
        summary: "Create a new user membership in a gym",
        tags: ["UserMembership"],
        operationId: "createUserMembership",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/UserMembershipController-createUserMembershipBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/UserMembershipController-createUserMembershipResponse"
                }
              }
            }
          }
        }
      },
      put: {
        summary: "Update user membership by id",
        tags: ["UserMembership"],
        operationId: "updateUserMembership",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/UserMembershipController-updateUserMembershipBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/UserMembershipController-updateUserMembershipResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/gyms/{gymId}/user-memberships/{userId}": {
      get: {
        summary: "Get user memberships in a gym",
        tags: ["UserMembership"],
        operationId: "getUserMemberships",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "userId",
            in: "path"
          },
          {
            schema: {
              type: "string",
              enum: ["all", "active"]
            },
            required: false,
            name: "type",
            in: "query"
          }
        ],
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/UserMembershipController-getUserMembershipsResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/gyms/{gymId}/user-membership-records": {
      put: {
        summary: "batch update user membership",
        tags: ["UserMembership"],
        operationId: "batchUpdateUserMembershipRecords",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/UserMembershipController-batchUpdateUserMembershipRecordsBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/UserMembershipController-batchUpdateUserMembershipRecordsResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/gyms/{gymId}/user-memberships/{membershipId}": {
      delete: {
        summary: "Delete user membership by id",
        tags: ["UserMembership"],
        operationId: "deleteUserMembership",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "membershipId",
            in: "path"
          }
        ],
        responses: {
          "200": {
            description: "\u6210\u529F\u54CD\u5E94"
          }
        }
      }
    },
    "/api/gyms/{gymId}/public-records": {
      get: {
        summary: "Get group course records by start date and period",
        tags: ["CourseRecord-Public"],
        operationId: "getPublicCourseRecords",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "path"
          },
          {
            schema: {
              type: "string"
            },
            required: true,
            name: "date",
            in: "query"
          },
          {
            schema: {
              type: "string"
            },
            required: true,
            name: "period",
            in: "query"
          }
        ],
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/CourseRecordPublicController-getCourseRecordsResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/coaches/{coachId}/records": {
      get: {
        summary: "Get coach records by start date and period",
        tags: ["Coach"],
        operationId: "getCoachRecords",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "coachId",
            in: "path"
          },
          {
            schema: {
              type: "string"
            },
            required: true,
            name: "date",
            in: "query"
          },
          {
            schema: {
              type: "string"
            },
            required: true,
            name: "period",
            in: "query"
          }
        ],
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/CoachController-getCoachRecordsResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/users/{userId}/group/records": {
      post: {
        summary: "Create a new user record",
        tags: ["UserRecord"],
        operationId: "createUserGroupRecord",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "userId",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/UserRecordController-createUserGroupRecordBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/UserRecordController-createUserGroupRecordResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/users/{userId}/private/records": {
      post: {
        summary: "Create a new user record",
        tags: ["UserRecord"],
        operationId: "createUserPrivateRecord",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "userId",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/UserRecordController-createUserPrivateRecordBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/UserRecordController-createUserPrivateRecordResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/users/{userId}/records": {
      get: {
        summary: "Get user record by id",
        tags: ["UserRecord"],
        operationId: "getUserRecord",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "userId",
            in: "path"
          },
          {
            schema: {
              type: "string"
            },
            required: false,
            name: "date",
            in: "query"
          },
          {
            schema: {
              type: "string"
            },
            required: false,
            name: "period",
            in: "query"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "gymId",
            in: "query"
          }
        ],
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/UserRecordController-getUserRecordResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/users/{userId}/records/{recordId}": {
      put: {
        summary: "Update user record by id",
        tags: ["UserRecord"],
        operationId: "updateUserRecord",
        parameters: [
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "userId",
            in: "path"
          },
          {
            schema: {
              type: "string",
              minLength: 1
            },
            required: true,
            name: "recordId",
            in: "path"
          }
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                $ref: "#/components/schemas/UserRecordController-updateUserRecordBodyRequest"
              }
            }
          }
        },
        responses: {
          "200": {
            description: "success",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/UserRecordController-updateUserRecordResponse"
                }
              }
            }
          }
        }
      }
    }
  }
};

export {
  APIDocument
};
